import React, { useRef, useLayoutEffect } from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Formulario from "../components/Formulario"


import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import animateReveal from "../components/gs_reveal";


export default function ReformaApartamentos() {

    gsap.registerPlugin(ScrollTrigger);

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let scrollTriggers = [];
        scrollTriggers = animateReveal(revealRefs.current);
        return () => {
            scrollTriggers.forEach((t) => t.kill(true));
        };
    }, []);

    const addToRevealRefs = (el) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    // end gsap
    return (
        <Layout>
            <Helmet
                title="Empresa de reformas | Pide presupuesto ya"
                meta={[
                    { name: 'description', content: 'Reformas.Top es una empresa de reformas integrales. Pide presupuesto gratis y sin compromiso.' },
                ]}
            />
            <div className="bg-[url('https://reformas.top/background/reformas-de-apartamentos.webp')] bg-fixed bg-cover bg-bottom bg-no-repeat">
                <div className=" container flex flex-col xl:flex-row justify-between py-5">
                    <div className=" flex items-center justify-center 2xl:px-10">
                        <h1 className="text-white text-center xl:text-left text-4xl tracking-tight sm:text-6xl lg:text-6xl 2xl:text-7xl leading-[1.2] mb-10">
                        <span class="text-white">Reformas de apartamentos, pisos y dúplex en <strong>Lorem Ipsum</strong></span>
                        </h1>
                    </div>
                    <div>
                        <Formulario />
                    </div>
                </div>
            </div>
      <section className="container mt-20">
      <div className="max-w-4xl mx-auto">
        <p className=" text-text text-xl text-center"></p>
        <p className=" text-text text-xl text-center">Para cualquier tipo de reformas tenemos la experiencia y el personal
          cualificado que nos permite asegurar calidad, profesionalismo y un trabajo impecable. Por esta razón, nuestra
          empresa de reformas en  es considerada como la mejor opción para toda clase de proyectos que tengas.
        </p>
      </div>
      <div>
        <h2 className="mt-20 mb-5 text-5xl text-text font-bold text-center leading-[4rem]">¿Cuánto cuesta la reforma
          integral
          de un apartamento?</h2>
          <StaticImage src="../images/cuanto-cuesta-una-reforma.webp" alt="¿Cuánto cuesta una reforma integral?"  className="mx-auto mb-20"/>
        <div className="max-w-5xl mx-auto">
          <p className=" text-text text-xl text-center">Los presupuestos, a la hora de planear una reforma de un
           , varían en función de varios aspectos. Primeramente, la naturaleza del proyecto
            tiene mucho que ver: si es una reparación relacionada a la habitabilidad, o si es una remodelación meramente
            estética.</p>
          <p className=" text-text text-xl text-center">
            En ambos casos, los procedimientos requerirán de materiales y trabajos muy específicos que harán que
            el coste general del proyecto aumente o disminuya.</p>
          <p className=" text-text text-xl text-center">
            Sin embargo, algo que no puedes descuidar es la garantía de
            que la reforma que deseas realizar salga realmente como lo deseas, por eso debes tener cuidado con aquellas
            alternativas con precios “increíbles” que te prometen resultados mágicos.</p>
        </div>

      </div>
    </section>
    <section className="mb-10">
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center mb-20 lg:w-11/12 mx-auto">
          <div className="basis-1/2 mb-5">
          <StaticImage src="../images/por-que-reformar-un-piso.webp" alt="Reformas de Oficinas" />
          </div>
          <div className="basis-1/2 rounded-r-3xl lg:p-10">
            <h2 className="mb-5">¿Por qué reformar un piso?</h2>
            <p>
              Las reformas son necesarias en cualquier apartamento donde existan problemas de
              funcionalidad, ya sean directamente relacionados con los servicios básicos (agua, electricidad, gas, etc.)
              o con la distribución de los elementos dentro del espacio, como una pared en mal estado, o gabinetes
              demasiado grandes o pequeños.<br/> Del mismo modo, también deben realizarse donde se requiera una mejora
              estética, ya sea por cuestión de gustos, o porque quieras aumentar el valor de la propiedad. Estas
              reformas harán lucir tu como el lugar que realmente quieres para ti.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mb-20 lg:w-11/12 mx-auto">
          <div className="basis-1/2 mb-5">
          <StaticImage src="../images/reformas-personales.webp" alt="Reformas de Bares" />
            </div>
          <div className="basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-r-3xl p-5 lg:p-10">
            <h2 className="mb-5">Reformas personales</h2>
            <p>Son aquellas pensadas en tus exigencias específicas en espacio y habitabilidad. Son perfectas si deseas
              conservar tu propiedad por un largo período, ya que estarán hechas a tu gusto en la medida de lo posible.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center mb-20 lg:w-11/12 mx-auto">
          <div className="basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-l-3xl p-5 lg:p-10">
            <h2 className="mb-5">Reformar un apartamento para alquilarlo</h2>
            <p>En estos casos se realizan reformas apuntando directamente al aumento del valor de la propiedad,
              realizando los trabajos según las exigencias del mercado y las nuevas tendencias en materia de compra de
              un 
            </p>
          </div>
          <div className="basis-1/2 mb-5">
            <StaticImage src="../images/reformar-un-para-ventas.webp" alt="Reformas de Tiendas" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mb-20 lg:mb-52 lg:w-11/12 lg:mx-auto">
          <div className="basis-1/2 mb-5">
          <StaticImage src="../images/reformar-un-piso-para-alquilarlo.webp" alt="Reformas de Bares" />
            </div>
          <div className="basis-1/2 bg-gray-bg bg-opacity-25 lg:rounded-r-3xl p-5 lg:p-10">
            <h2 className="mb-5">Reformar un apartamento para alquilarlo</h2>
            <p>Son detalles principalmente enfocados en los servicios básicos. Así garantizarás a los futuros inquilinos
              un espacio cómodo y perfectamente habitable. También se pueden realizar mejoras estéticas. Estos trabajos
              también revalorizan significativamente tu propiedad.</p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center mb-20 lg:w-11/12 lg:mx-auto">
          <div className="basis-2/3 p-5 lg:p-10">
            <h2 className="mb-5">Presupuestos para reformas de apartamentos</h2>
            <p>A la hora de realizar el presupuesto se tendrán en cuenta todos los gastos que sean necesarios para
              entregarte el mejor trabajo. Esto puede incluir gastos de fontanería, arquitectura, albañilería,
              carpintería, iluminación, ventilación, etc. Todo esto irá de acuerdo a tus necesidades, siempre con la
              asesoría de nuestros profesionales, quienes te orientarán de la mejor manera para darle vida a tu
              proyecto. Tienes la garantía de recibir el mejor presupuesto en el sector en esta clase de trabajos, así
              como de toda clase de Reformas de locales y otras propiedades.</p>
          </div>
          <div className=" basis-1/3 flex justify-end">
          <StaticImage src="../images/presupuestos-para-reformas.webp" alt="Reformas de Restaurantes" />
          </div>
        </div>
      </div>
      <div className=" container">
        <div className=" max-w-xl mx-auto text-center  mb-10">
          <h2 className="mb-5 text-black"><strong>Reformas de pisos:</strong> <br/> Ideas y consejos</h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-24 mb-20">
          <div className="bg-white rounded-3xl p-5">
            <div className="flex flex-col">
              <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span
                  className="ico-service block icon-select"></span></div>
              <h3 className=" text-text font-semibold text-2xl">Ideas para reformas de pisos</h3>
              <p className="text-xl">
                Aunque encuentres inspiración en redes sociales y blogs de decoración, es necesario que
                cuentes con la asesoría adecuada para ver qué tan posible es realizar la obra que quieres. Tienes la
                opción realizar un boceto y buscar ciertos elementos que te gustaría tener en tu
               , luego, con nuestros servicios especiales vas a hacerlo posible.</p>
            </div>
          </div>
          <div className="bg-white rounded-3xl p-5">
            <div className="flex flex-col">
              <div className="bg-primary w-16 h-16 rounded-3xl flex justify-center items-center mr-3 mb-3"><span
                  className="ico-service block icon-construir"></span></div>
              <h3 className=" text-text font-semibold text-2xl">Consejos para reformas de pisos</h3>
              <p className="text-xl">También podrás encontrar toda clase de alternativas para reformas más grandes. En este
                enlace podrás conocer todo sobre las reformas de casas que ofrecemos actualmente
                https://reformas.top/casas/</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center mb-20 w-11/12 mx-auto">
          <div className="basis-1/2">
              <StaticImage src="../images/proyecto-para-reformas.webp" alt="Reformas de Oficinas" />
          </div>
          <div className="basis-1/2 rounded-r-3xl p-10">
            <h2 className="mb-5">Diseño y proyecto para reformar un piso</h2>
            <p>
              Después de hacerte una idea de que es lo que quieres conseguir con tus reformas, se debe realizar un arduo
              trabajo de estudio y diseño. Solo de esta manera se lograrán los resultados más adecuados en la estética y
              funcionalidad que deseas lograr. <br/>Deberás tener un poco de paciencia y acompañar todo el proceso con la
              mejor disposición, ya que usualmente se suelen presentar pequeños inconvenientes. Afortunadamente, con
              nuestro servicio podrás obtener el resultado que esperas…<br/><strong> ¡Estás en el lugar
                indicado!</strong>
            </p>
          </div>
        </div>
      </div>
    </section>

        </Layout>
    )
}